import axios from 'axios'

const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/customer/discount`

const getDiscount = (code) => {
  return axios.get(api + '/' + code)
}

export default {
  getDiscount
}
